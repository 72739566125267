export function very(sId) {
  if (sId.length === 15) {
    if (!/^\d{14}(\d|x)$/i.test(sId)) {
      return { state: false, message: '你输入的身份证长度或格式错误' }
    } else {
      sId = sId.substr(0, 6) + '19' + sId.substr(6, 9)
      sId += getVCode(sId)
    }
  }
  function getVCode(CardNo17) {
    // eslint-disable-next-line no-array-constructor
    var Wi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1)
    // eslint-disable-next-line no-array-constructor
    var Ai = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2')
    var cardNoSum = 0
    for (var i = 0; i < CardNo17.length; i++)cardNoSum += CardNo17.charAt(i) * Wi[i]
    var seq = cardNoSum % 11
    return Ai[seq]
  }
  var aCity = { 11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古', 21: '辽宁', 22: '吉林', 23: '黑龙江', 31: '上海', 32: '江苏', 33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南', 42: '湖北', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆', 51: '四川', 52: '贵州', 53: '云南', 54: '西藏', 61: '陕西', 62: '甘肃', 63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门', 91: '国外' }
  var iSum = 0
  // var info = ''
  if (!/^\d{17}(\d|x)$/i.test(sId)) {
    return { state: false, message: '你输入的身份证长度或格式错误' }
  }
  sId = sId.replace(/x$/i, 'a')
  if (aCity[parseInt(sId.substr(0, 2))] === null) {
    return { state: false, message: '你的身份证地区非法' }
  }
  var sBirthday = sId.substr(6, 4) + '-' + Number(sId.substr(10, 2)) + '-' + Number(sId.substr(12, 2))
  var d = new Date(sBirthday.replace(/-/g, '/'))
  if (sBirthday !== (d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate())) {
    return { state: false, message: '身份证上的出生日期非法' }
  }
  for (var i = 17; i >= 0; i--) iSum += (Math.pow(2, i) % 11) * parseInt(sId.charAt(17 - i), 11)
  if (iSum % 11 !== 1) {
    return { state: false, message: '你输入的身份证号非法' }
  }
  return { state: true }
}
