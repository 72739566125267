<template>
  <div v-show="countdown > 0" class="countdown_background">
    <div class="countdown">
      <span>正在实名认证并生成数字证书中..</span>
      <span class="CountdownSecondsec">{{ countdown }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountDown',
  data() {
    return {
      countdown: 0
    }
  },
  methods: {
    show() {
      this.countdown = 30
      this.countdowncount()
    },
    countdowncount() {
      setTimeout(() => {
        if (this.countdown > 0) {
          this.countdown--
          this.countdowncount()
        }
      }, 1000)
    },
    close() {
      this.countdown = 0
    }
  }
}
</script>

<style scoped>
  .countdown{
    width: 60vw;
    height: 60vw;
    max-width:300px;
    max-height:300px;
    border-radius: 50%;
    background-color: #409EFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }
  .countdown_background{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index:9999;
    background-color:rgba(0,0,0,0.5);
  }
  .CountdownSecondsec{
    font-size: 38px;
  }
</style>
